import { useEffect } from "react";

import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import utc from "dayjs/plugin/utc";
import localizedFormat from "dayjs/plugin/localizedFormat";

dayjs.extend(relativeTime);
dayjs.extend(utc);
dayjs.extend(localizedFormat);

import "dayjs/locale/en-gb";

const DayjsConfig = () => {
  useEffect(() => {
    const locale = dayJsLocaleFromBrowser() || "en-gb";
    // Default to en-gb as it would otherwise default to American-style which is less widespread.
    // America specifically is well handled by "dayJsLocaleFromBrowser".
    // Defaulting here as we don't currently handle other locales well.
    // (Would have to import them one by one above, which isn't great.)
    if (locale !== undefined) {
      const loadedLocale = dayjs.locale(locale);
      console.log("Loaded locale", loadedLocale);
    }
  }, []);
  return (
    <>
      {/* This script does not work; loadedLocale is always "en". Not sure why. */}
      {/* {locale && (
        <Script
          //   src={`https://cdn.jsdelivr.net/npm/dayjs/locale/${locale}.js`}
          onLoad={() => {
            const loadedLocale = dayjs.locale("en-gb");
            console.log("Loaded locale", loadedLocale);
          }}
        />
      )} */}
    </>
  );
};

export default DayjsConfig;

const dayJsLocaleFromBrowser = (): string | undefined => {
  const navigatorLanguages: string[] | null = [...navigator.languages] || (navigator.language ? [navigator.language] : null);
  if (!navigatorLanguages || !navigatorLanguages.length) {
    return;
  }

  // The comment https://github.com/iamkun/dayjs/issues/732#issuecomment-554383261 contains an example list of locales available in dayjs
  // (Most of this function is adapted from that code as well)
  // For now we limit it to en and en-gb. Consider expanding when we can load them on-demand.
  const dayjsLocales = ["en", "en-gb"];

  const checkLocale = (locale: string): string | undefined => {
    if (["en", "en-us"].includes(locale)) return "en";
    // if (locale === "zn") return "zh-cn";
    // if (locale === "no") return "nb";
    if (dayjsLocales.includes(locale)) return locale;
  };

  for (const language of navigatorLanguages) {
    const lang = language.toLowerCase();
    const acceptableLocale: string | undefined = checkLocale(lang) || (lang.includes("-") && checkLocale(lang.split("-")[0]));
    if (acceptableLocale) {
      return acceptableLocale;
    }
  }
};
