import KeyboardShortcut, { KeyboardShortcutShade } from "@library/KeyboardShortcut";
import { ConditionalWrapper } from "lib/utils";
import Link from "next/link";
import React, { useRef } from "react";
import { classNames } from "./utils/classNames";
import { UrlObject } from "url";

export type ButtonSize = 24 | 32 | 40 | 52;

export const enum ButtonShade {
  Blue = "blue",
  Gray = "gray",
  Red = "red",
  Yellow = "yellow",
  Green = "green",
}

export interface ButtonProps {
  size?: ButtonSize;
  styling?: "solid" | "outline" | "ghost" | "link";
  shade?: ButtonShade; // NB: not all combinations work. Can't have solid in gray for example.
  active?: boolean;
  disabled?: boolean;
  loading?: boolean;
  type?: "button" | "submit" | "reset";

  IconLeft?: React.FunctionComponent<React.ComponentProps<"svg">> | React.FunctionComponent<React.ComponentProps<"img">>;
  IconRight?: React.FunctionComponent<React.ComponentProps<"svg">> | React.FunctionComponent<React.ComponentProps<"img">>;

  className?: string;

  onClick?: React.MouseEventHandler<HTMLButtonElement | HTMLAnchorElement>;
  // Disallow focus after click or through tab indexing.
  disableFocus?: boolean;

  // TODO: Questionable prop. Using className feels more appropriate.
  block?: boolean;

  // Keyboard just displays the keyboard shortcuts, doesn't make it interactive.
  keyboard?: (string | React.ReactNode)[];

  // When used as a link
  href?: string | UrlObject;
  target?: string;
  rel?: string;

  children?: React.ReactNode;
}

const Button = React.forwardRef(function ButtonComponent(
  props: ButtonProps,
  ref: React.ForwardedRef<HTMLButtonElement> | React.ForwardedRef<HTMLAnchorElement>
) {
  const {
    type = "button",
    styling = "outline",
    size = 32,
    shade = ButtonShade.Gray,
    loading,
    disabled,
    href,
    disableFocus,
    onClick: onClickProp,
    active,
    IconLeft,
    IconRight,
    className,
    ...rest
  } = props;

  // For some UI buttons we don't want to want to allow focus
  // after a mouse click (which is the default behaviour). To do this we
  // use mouseDown events and preventDefault on the event.
  let onClick: React.MouseEventHandler<HTMLButtonElement | HTMLAnchorElement> | undefined;
  let onMouseDown: React.MouseEventHandler<HTMLButtonElement | HTMLAnchorElement> | undefined;
  if (disableFocus) {
    onClick = (event) => {
      event.preventDefault();
    };
    onMouseDown = (event) => {
      event.preventDefault();
      if (onClickProp) {
        onClickProp(event);
      }
    };
  } else {
    onClick = onClickProp;
    onMouseDown = undefined;
  }

  const classes = {
    base: "inline-flex justify-center items-center relative select-none group font-bold",
    content: {
      24: "text-12-14 gap-6",
      32: "text-14-16 gap-8",
      40: "text-14-16 gap-8",
      52: "text-18-28 gap-16",
    },
    // Applied to all but 'link' buttons
    // The awkward change in padding is to account for the border in outline
    //  buttons so that '40' remains 40 px tall and the width doesn't shift
    padding: {
      24: classNames("rounded-[4px]", styling === "outline" ? "py-4 px-[7px]" : "py-[5px] px-8"),
      32: classNames("rounded", styling === "outline" ? "py-[7px] px-[11px]" : "py-8 px-12"),
      40: classNames("rounded ", styling === "outline" ? "py-[11px] px-[15px]" : "py-12 px-16"),
      52: classNames("rounded ", styling === "outline" ? "py-[11px] px-[19px]" : "py-[12px] px-20"),
    },
    icon: {
      24: "w-12 h-12",
      32: "w-16 h-16",
      40: "w-20 h-20 -my-2", // Negative margin otherwise it makes the button too tall
      52: "w-20 h-20",
    },
    block: "w-full",
    solid: {
      base: "focus:outline-none focus:ring focus:ring-blue-300 active:bg-blue-700", // applied to all
      normal: "bg-blue-600 text-white hover:bg-blue-700 ", // applied if not disabled or active
      disabled: "text-white bg-gray-400",
      active: "bg-blue-700 text-white shadow-inner",
    },
    outline: {
      base: "border",
      normal: classNames(
        shade === ButtonShade.Blue && "border-blue-400 text-blue-600 hover:text-blue-700 active:bg-blue-200 active:text-blue-600",
        shade === ButtonShade.Gray && "border-gray-400 text-gray-700 hover:border-gray-500 hover:text-gray-800 active:bg-gray-200 active:text-gray-700",
        // These are less common shades and less thought has been put into the colors
        shade === ButtonShade.Green && "border-green-300 text-green-600 hover:text-green-700 active:bg-green-200 active:text-green-600",
        shade === ButtonShade.Yellow && "border-yellow-300 text-yellow-700 hover:text-yellow-800 active:bg-yellow-200 active:text-yellow-00",
        shade === ButtonShade.Red && "border-red-300 text-red-600 hover:text-red-700 active:bg-red-200 active:text-red-600"
      ),
      disabled: "border-gray-300 text-gray-400",
      active: classNames(
        shade === ButtonShade.Blue && "bg-blue-200 text-blue-600",
        shade === ButtonShade.Gray && "bg-gray-200 text-gray-700",
        shade === ButtonShade.Green && "bg-green-200 text-green-700",
        shade === ButtonShade.Yellow && "bg-yellow-200 text-yellow-700",
        shade === ButtonShade.Red && "bg-red-200 text-red-700"
      ),
    },
    ghost: {
      base: "border-none focus:outline-none focus:ring focus:ring-blue-300 ",
      normal:
        shade === ButtonShade.Blue
          ? "text-blue-600 hover:text-blue-700 active:bg-blue-200 active:text-blue-600"
          : "text-gray-700 hover:text-gray-800 active:bg-gray-200 active:text-gray-700",

      disabled: "text-gray-400",
      // Same as outline
      active: shade === ButtonShade.Blue ? "bg-blue-200 text-blue-600" : "bg-gray-200 text-gray-700",
    },
    link: {
      // TODO:!!! this is just copied from the 'ghost' atm
      base: "border-none focus:outline-none underline focus:ring focus:ring-blue ",
      normal: "text-gray-700 hover:text-black",
      disabled: "text-gray-300",
      active: "",
    },
    cursor: loading ? "cursor-progress" : disabled ? "cursor-not-allowed" : "cursor-pointer",
  };

  const Element = href ? "a" : "button";

  return (
    // This is to use Next's Link component when acting as a link to enable
    // Client-side transitions. I don't think there's anything wrong with using
    // this still when the href is for an external link.
    <ConditionalWrapper
      condition={href && href}
      wrapper={(children) => {
        return (
          <Link href={href} passHref>
            {children}
          </Link>
        );
      }}
    >
      <Element
        // Spread props so this works with RadixUI's asChild.
        {...rest}
        ref={ref as any}
        type={type}
        onClick={onClick}
        onMouseDown={onMouseDown}
        tabIndex={disabled || disableFocus ? -1 : 0}
        className={classNames(
          className,
          classes.base,
          classes.content[size],
          classes.padding[size],
          classes[styling].base,
          props.block && classes.block,
          disabled ? classes[styling].disabled : active ? classes[styling].active : classes[styling].normal,
          classes.cursor
        )}
        disabled={disabled}
        {...(href
          ? {
              target: props.target,
              rel: props.rel,
            }
          : {})}
      >
        {IconLeft && <IconLeft className={classNames(classes.icon[size], "shrink-0")} />}
        {props.children}
        {/* {JSON.stringify(props.keyboard)} */}
        {/* TODO: Heads up that the shortcut isn't fully designed/finished and neither is how they should be used with all the buttons */}
        {props.keyboard?.length > 0 &&
          props.keyboard.map((key, i) => (
            <KeyboardShortcut key={i} shade={KeyboardShortcutShade.Blue} className={classNames(i !== 0 && "-ml-6 ", "-my-4")} disabled={props.disabled}>
              {key}
            </KeyboardShortcut>
          ))}
        {IconRight && <IconRight className={classNames(classes.icon[size], "shrink-0")} />}
      </Element>
    </ConditionalWrapper>
  );
});

export default Button;
