import React from "react";
import { CheckCircleIcon, ExclamationCircleIcon, ExclamationIcon, XIcon } from "@heroicons/react/outline";
import toast, { Toast as ToastInterface, Toaster } from "react-hot-toast";
import { classNames } from "lib/utils";
import { InsightIcon } from "@library/Icons";
import IconButton, { IconButtonShade } from "@library/IconButton";

export const enum ToastVariant {
  Info = "Info",
  Success = "Success",
  Warning = "Warning",
  Error = "Error",
}

interface ToastProps {
  toast: ToastInterface;
  title: React.ReactNode;
  description?: React.ReactNode;
  variant: ToastVariant;
}

/** Styling for a Humanloop toast. Intended for use by `hlToast()`. */
export const Toast = ({ toast: t, title, description, variant }: ToastProps) => {
  const variants = {
    [ToastVariant.Info]: { classes: "text-blue-600", icon: InsightIcon },
    [ToastVariant.Success]: { classes: "text-green-600", icon: CheckCircleIcon },
    [ToastVariant.Warning]: { classes: "text-yellow-600", icon: ExclamationIcon },
    [ToastVariant.Error]: { classes: "text-red-600", icon: ExclamationCircleIcon },
  };
  const Icon = variants[variant].icon;

  return (
    <div className={classNames("items-top my-2 flex items-start", variants[variant].classes)}>
      <div className={"mr-16 h-24 w-24 flex-shrink-0 p-2"}>
        <Icon />
      </div>
      <div className="mr-24  flex flex-grow flex-col">
        <span className={"text-base font-bold text-gray-800"}>{title}</span>
        {description && <span className="mt-8 max-w-prose text-12-14 text-gray-700">{description}</span>}
      </div>
      <IconButton className="shrink-0" Icon={XIcon} size={24} shade={IconButtonShade.Black} onClick={() => toast.dismiss(t.id)} />
    </div>
  );
};

/** Modified react-hot-toast's `Toaster` for app-wide configuration. */
export const HumanloopToaster = () => {
  return <Toaster position="bottom-right" containerStyle={{ bottom: "100px" }} toastOptions={{ className: "text-sm", style: { maxWidth: 480 } }} />;
};

/**
 * Modified react-hot-toast's `toast()` that uses the styled library's Toast component.
 * @param toastProps Props for library Toast component
 */
export const hlToast = (toastProps: Omit<ToastProps, "toast"> & { duration?: number }) => {
  toast((t) => <Toast toast={t} {...toastProps} />, { duration: toastProps.duration });
};
