import { classNames } from "lib/utils";
import React from "react";

export const enum KeyboardShortcutShade {
  Blue = "Blue",
  Gray = "Gray",
}

export type KeyboardShortcutSize = 20 | 24;

export interface KeyboardShortcutProps {
  className?: string;
  children: React.ReactNode;
  styling?: "solid" | "outline";
  shade?: KeyboardShortcutShade;
  size?: KeyboardShortcutSize;
  disabled?: boolean;
}

const KeyboardShortcut = ({
  className,
  children,
  styling = "solid",
  shade = KeyboardShortcutShade.Blue,
  size = 20,
  disabled = false,
}: KeyboardShortcutProps) => {
  const classes = {
    base: "flex items-center justify-center capitalize select-none rounded-sm font-bold whitespace-nowrap",
    size: {
      16: "h-16 min-w-[16px] px-[3.5px] text-11-14",
      20: "h-20 min-w-[20px] px-4 text-12-14",
      24: "h-24 min-w-[24px] px-6 text-base",
    },
    styling: {
      solid: {
        [KeyboardShortcutShade.Gray]: disabled ? "text-gray-500 bg-gray-100" : "text-gray-600 bg-gray-200 group-active:bg-gray-300",
        [KeyboardShortcutShade.Blue]: disabled ? "text-blue-300 bg-gray-200" : "text-blue-600 bg-blue-300 group-active:bg-blue-200",
      },
      // DECRECATED: This seems to no longer be part of our design library.
      //  Keeping for now as it looks ok and useful to me.
      outline: {
        [KeyboardShortcutShade.Gray]: "text-gray-400 border border-grey-400 group-active:bg-gray-300",
        [KeyboardShortcutShade.Blue]: "text-blue-400 border border-blue-400 group-active:bg-blue-300",
      },
    },
  };

  return <div className={classNames(classes.base, classes.size[size], classes.styling[styling][shade], className)}>{children}</div>;
};

export default KeyboardShortcut;
