/**
 * Patch removeChild and insertBefore to avoid throwing errors.
 *
 * React may throw an error when things like browser extensions mess with the DOM.
 * This patch results in a performance degradation (unmeasured).
 * For more details see:
 * https://github.com/facebook/react/issues/11538#issuecomment-417504600
 * and
 * https://github.com/facebook/react/issues/17256#issuecomment-829685981
 */
export const patchRemoveChildAndInsertBefore = () => {
  if (typeof Node === "function" && Node.prototype) {
    const originalRemoveChild = Node.prototype.removeChild;
    Node.prototype.removeChild = function (child) {
      if (child.parentNode !== this) {
        if (console) {
          console.error("Cannot remove a child from a different parent", child, this);
        }
        return child;
      }
      // eslint-disable-next-line prefer-rest-params
      return originalRemoveChild.apply(this, arguments);
    };

    const originalInsertBefore = Node.prototype.insertBefore;
    Node.prototype.insertBefore = function (newNode, referenceNode) {
      if (referenceNode && referenceNode.parentNode !== this) {
        if (console) {
          console.error("Cannot insert before a reference node from a different parent", referenceNode, this);
        }
        return newNode;
      }
      // eslint-disable-next-line prefer-rest-params
      return originalInsertBefore.apply(this, arguments);
    };
  }
};
