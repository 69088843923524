import React, { ForwardedRef, forwardRef } from "react";
import { classNames } from "./utils/classNames";

// Refers to the outer button size
export type IconButtonSize = 12 | 16 | 20 | 24 | 32 | 40 | 48;
export const enum IconButtonShade {
  Gray = "Gray",
  Black = "Black",
  Blue = "Blue",
}

export interface IconButtonProps {
  // Regular button props https://developer.mozilla.org/en-US/docs/Web/HTML/Element/button
  active?: boolean;
  isWait?: boolean;
  disabled?: boolean;
  type?: "button" | "submit" | "reset";
  value?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;

  // Our custom props
  baseStyle?: boolean;
  Icon: (props: React.ComponentProps<"svg">) => JSX.Element;
  size?: IconButtonSize; // Outer size
  iconSize?: IconButtonSize; // Icon size override (using the same mapping as overall size) 
  className?: string;
  style?: React.CSSProperties;
  shade?: IconButtonShade;
}

const IconButton = (props: IconButtonProps, ref: ForwardedRef<HTMLButtonElement>): JSX.Element => {
  const { type = "button", size = "24", iconSize = null, shade = IconButtonShade.Black, Icon, active, baseStyle, ...rest } = props;

  const classes = {
    base: "inline-flex justify-center items-center relative select-none rounded",
    size: {
      12: "w-12 h-12",
      16: "w-16 h-16",
      20: "w-20 h-20",
      24: "w-24 h-24",
      32: "w-32 h-32",
      40: "w-40 h-40",
      48: "w-48 h-48",
    },
    // These sizes don't match the equivalent Button icons (they're bigger)
    icon: {
      12: "w-8 h-8",
      16: "w-8 h-8",
      20: "w-12 h-12",
      24: "w-16 h-16",
      32: "w-20 h-20",
      40: "w-20 h-20",
      48: "w-24 h-24",
    },
    styling: {
      [IconButtonShade.Black]: {
        base: "focus:outline-none focus:ring focus:ring-gray-200",
        normal: "text-black hover:bg-gray-100 active:bg-gray-200",
        disabled: "text-gray-400 hover:bg-gray-100",
        active: "text-black bg-gray-200 hover:bg-gray-200 active:bg-gray-200",
      },
      [IconButtonShade.Gray]: {
        base: "focus:outline-none focus:ring focus:ring-gray-300",
        normal: "text-gray-600 hover:bg-gray-200 active:bg-gray-300",
        disabled: "text-gray-400 hover:bg-gray-100",
        active: "text-gray-800 bg-gray-200 hover:bg-gray-200 active:bg-gray-200",
      },
      [IconButtonShade.Blue]: {
        base: "focus:outline-none focus:ring focus:ring-gray-300",
        normal: "text-blue-600 hover:bg-gray-200 active:bg-gray-300",
        disabled: "text-blue-400 hover:bg-gray-100",
        active: "text-blue-800 bg-gray-200 hover:bg-gray-200 active:bg-gray-200",
      },
    },
    disabled: props.isWait ? "cursor-wait" : "cursor-not-allowed",
  };

  const shadeStyling = classes.styling[shade];

  return (
    <button
      // Spread props so this works with RadixUI's asChild.
      {...rest}
      ref={ref}
      type={type}
      onClick={props.onClick}
      className={classNames(
        props.className,
        classes.base,
        classes.size[size],
        shadeStyling.base,
        !baseStyle && props.disabled && classes.disabled,
        !baseStyle && (props.disabled ? shadeStyling.disabled : active ? shadeStyling.active : shadeStyling.normal)
      )}
      disabled={props.disabled}
      style={props.style}
    >
      <props.Icon className={props.iconSize ? classes.icon[props.iconSize] : classes.icon[size]} />
    </button>
  );
};
export default forwardRef<HTMLButtonElement, IconButtonProps>(IconButton);
