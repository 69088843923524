import React, { createContext, useContext } from "react";
import useLocalStorage from "../lib/use-local-storage";

const debugContext = createContext();

export function ProvideDebug({ children }) {
  const debug = useProvideDebug();
  return <debugContext.Provider value={debug}>{children}</debugContext.Provider>;
}

// Hook for child components to get the auth object and re-render when it changes.
export const useDebug = () => {
  return useContext(debugContext);
};

// Provider hook that creates auth object and handles state
function useProvideDebug() {
  const [debug, setDebug] = useLocalStorage("debug", false);
  return [debug, setDebug];
}
