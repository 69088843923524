import { ApiService } from "./api.service";

interface UpdateRequest {
  annotationId: string;
  reviewed?: boolean;
  start?: number;
  end?: number;
  text?: string;
  labelId?: string;
  strength?: number;
}

const AnnotationApi = {
  createAnnotation: ({ annotation }) => ApiService.post(`/annotations`, annotation),

  upsertAnnotation: ({ annotation }) => ApiService.put(`/annotations/${annotation.id}`, annotation),

  updateAnnotation: ({ annotationId, reviewed, start, end, text, labelId, strength }: UpdateRequest) =>
    ApiService.patch(`/annotations/${annotationId}`, { reviewed, start, end, text, label_id: labelId, strength }),

  removeAnnotation: ({ annotationId }) => ApiService.remove(`/annotations/${annotationId}`),
};

export default AnnotationApi;
