import { AxiosPromise } from "axios";
import { getAuthToken } from "lib/use-auth";
import useSWR, { SWRConfiguration } from "swr";
import { ApiService } from "./api.service";
import { BaseOrganization, BaseTeam, User, UserOrganizationRole, UserTeamRole } from "./organizations.service";

interface UserTeam {
  role: UserTeamRole;
  team: BaseTeam;
}

export interface UserOrganization {
  role: UserOrganizationRole;
  organization: BaseOrganization;
}

/**
 * User returned by the /me endpoint.
 */
export interface CurrentUser extends User {
  teams: UserTeam[];
  organizations: UserOrganization[];
}

export const useCurrentUser = (swrOptions: SWRConfiguration<CurrentUser> = {}) => {
  const { data, error, mutate } = useSWR<CurrentUser>([`/v3/users/me`, getAuthToken()], swrOptions);
  return { user: data, error: error, loading: !data && !error, mutate };
};

interface CreateUserRequest {
  password: string;
  email_address: string;
  full_name?: string;
}

interface CreateUserResponse {
  id: string;
  email_address: string;
  full_name: string | null;
  verified: boolean;
}

export const createUser = (request: CreateUserRequest): AxiosPromise<CreateUserResponse> => {
  return ApiService.post(`/v3/users`, request);
};

export const resendVerification = (email: string): AxiosPromise<void> => {
  return ApiService.post(`/v3/users/resend-verification`, { email_address: email });
};

export interface VerifyUserRequest {
  token: string;
  password?: string;
  full_name?: string;
}

interface VerifyUserResponse {
  user: CreateUserResponse;
  access_token: string;
  token_type: "bearer";
}

export const verifyUser = (request: VerifyUserRequest): AxiosPromise<VerifyUserResponse> => {
  return ApiService.post(`/v3/users/verify`, request);
};
