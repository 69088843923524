import memoize from "@emotion/memoize";
import dayjs from "dayjs";
import { NON_BREAKING_SPACE, SPACE } from "lib/constants";
import { nanoid } from "nanoid";
import shajs from "sha.js";
import { Annotation } from "types/annotations";

export interface ObjectLiteral {
  [key: string]: any;
}

export function isEmpty(obj) {
  return Object.keys(obj).length === 0;
}

export function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export enum ObjectTypes {
  Annotation = "Annotation",
  Placeholder = "Placeholder",
}

export function generateId(objectType: ObjectTypes) {
  switch (objectType) {
    case ObjectTypes.Annotation:
      return `ann_${nanoid()}`;
    case ObjectTypes.Placeholder:
      return nanoid();
    default:
      return nanoid();
  }
}

export const ConditionalWrapper = ({ condition, wrapper, children }) => (condition ? wrapper(children) : children);

// From https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/flat
// (newer JS so not in all browser environments yet)
export function flattenArray(array) {
  return array.reduce((accumulator, value) => accumulator.concat(value), []);
}

export function pluralise(number) {
  return number === 1 ? "" : "s";
}

export function print(object) {
  return JSON.stringify(object, null, 2);
}

export function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export const log = console.log.bind(console);

export function stringify(object) {
  return JSON.stringify(object, null, 2);
}

// Convert string to a number between 0 and 360
export const stringToHueNumber = memoize(function stringToHueNumber(string) {
  if (!string) {
    return 0;
  }

  // We could use window.crypto.subtle.digest('SHA-256', string);
  // but that's async, and we want something sync as we use this for rendering purposes
  const encoded = shajs("sha256").update(string).digest("hex");
  const decimal = BigInt(`0x${encoded}`) % BigInt(360);
  return Number(decimal);
});

interface Colors {
  backgroundColor: string;
  color: string;
  borderColor: string;
}

export const stringToHSLA: (string: string) => Colors = memoize(function (string) {
  const pastelColor = stringToHueNumber(string);
  const BACKGROUND_SATURATION = 94;
  const BACKGROUND_LIGHTNESS = 86;

  const TEXT_SATURATION = 100;
  const TEXT_LIGHTNESS = 21;

  const BORDER_SATURATION = 100;
  const BORDER_LIGHTNESS = 78;

  return {
    backgroundColor: `hsla(${pastelColor}deg, ${BACKGROUND_SATURATION}%, ${BACKGROUND_LIGHTNESS}%, 1)`,
    color: `hsla(${pastelColor}deg, ${TEXT_SATURATION}%, ${TEXT_LIGHTNESS}%, 1)`,
    borderColor: `hsla(${pastelColor}deg, ${BORDER_SATURATION}%, ${BORDER_LIGHTNESS}%, 1)`,
  };
});

export const changeHSLAAlpha = (color: string, alpha: number): string => {
  return color.substr(0, color.lastIndexOf("1")) + `${alpha})`;
};

// Note object.assign() will only go one level deep.
// See https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/assign#Deep_Clone
export function deepClone(object: any): any {
  return JSON.parse(JSON.stringify(object));
}

export function getRandomString(length: number = 8) {
  return Math.random().toString(16).substr(2, length);
}

export function isOnlyWhitespace(string) {
  return string.length && !string.trim().length;
}

export function hasLeadingOrTrailingWhitespace(string) {
  return string.trim().length !== string.length;
}

export function cleanAnnotationText(annotationText) {
  if (!annotationText || isOnlyWhitespace(annotationText)) {
    log("String is empty spaces");
    annotationText = NON_BREAKING_SPACE;
  } else if (hasLeadingOrTrailingWhitespace(annotationText)) {
    annotationText = annotationText.replace(SPACE, NON_BREAKING_SPACE);
  }
  return annotationText;
}

export function isParamInUrl(param: string, window?: Window): boolean {
  if (!window) {
    return false;
  }
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.has(param);
}

export async function asyncForEach<T>(array: T[], iterator: (item: T, index: number, array: T[]) => Promise<void>) {
  for (let index = 0; index < array.length; index++) {
    await iterator(array[index], index, array);
  }
}

export const scrollLabelIntoView = async (annotation: Annotation) => {
  log(`Scrolling and running resize events ...`);
  const targetAnnotation: any = document.getElementById(`annotation-id-${(annotation as any).id || annotation.external_id}`);

  if (targetAnnotation) {
    // Scrolling will affect label positions (as rects depend on scrollTop, see get-label-coordinates.js)
    targetAnnotation.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
    window.dispatchEvent(new Event("resize"));

    targetAnnotation.classList.add("font-bold");
    await sleep(1000);
    targetAnnotation.classList.remove("font-bold");
  }
};

export const isPropertyDuplicated = (property: string, itemToCheck: ObjectLiteral, items: Array<ObjectLiteral>) => {
  return (
    items.filter((item) => {
      return itemToCheck[property] === item[property];
    }).length > 1
  );
};

const SI_SYMBOLS = ["", "K", "M", "G", "T", "P", "E"];

export const formatNumberSI = (number: number): string => {
  if (number === 0) {
    return `${number}`;
  }

  const tier = Math.trunc(Math.log10(Math.abs(number)) / 3);
  if (tier === 0) return `${number}`;

  const suffix = SI_SYMBOLS[tier];
  const scaled = number / Math.pow(10, tier * 3);

  return scaled.toFixed(1) + suffix;
};

export enum Size {
  sm = "sm",
  md = "md",
  lg = "lg",
}

export const downloadFile = (data: BlobPart, fileName: string, type: string = "application/pdf"): void => {
  // Hack required to keep filename.
  // Otherwise this would have been preferable:
  //   const file = new File([data], fileName, { type: "application/pdf" });
  //   const fileURL = URL.createObjectURL(file);
  //   window.open(fileURL);
  // See https://stackoverflow.com/a/60378502
  const a = document.createElement("a");
  a.href = URL.createObjectURL(new Blob([data], { type }));
  a.setAttribute("download", fileName);
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

export const getTimestampForFileName = (): string => {
  return dayjs().format("YYYY-MM-DD-HH-mm-ss");
};

export const getFileNameFromS3Path = (path: string): string => path.split("\\").pop().split("/").pop();
