import { getAuthToken } from "lib/use-auth";
import axios, { AxiosPromise, AxiosRequestConfig, Method } from "axios";
import SSE from "./sse";

// next.js will replace these values at build time, so we can't use destructuring here.
// https://nextjs.org/docs/basic-features/environment-variables
const baseProxyPath = process.env.PROXY_BASE;
const baseAPIPath = process.env.SERVER_API_URI || process.env.NEXT_PUBLIC_API_URI;

// `window` is undefined on the server and defined on the client, so we use it to detect
// which environment we're in
//
// From the client, this should be set to the prefix next.js uses
// to proxy backend requests.
//
// For SSR, it should be set to the URL of the backend API
// since next does not proxy server-side requests
const serviceBasePath = typeof window === "undefined" ? baseAPIPath : baseProxyPath;

const request = (method: Method, url: string, data: any = {}, config: AxiosRequestConfig = {}): AxiosPromise<any> => {
  // Always send the latest JWT in the header so we don't
  // have auth'd requests still being sent after logout.
  const token = getAuthToken() || process.env.TEST_AUTH_COOKIE || "";
  const instance = axios.create({
    baseURL: serviceBasePath,
    headers: { Authorization: `Bearer ${token}` },
  });
  return instance({ method, url, data, ...config });
};

const get = (url: string, config: AxiosRequestConfig = {}) => {
  return request("GET", url, {}, config);
};

const post = (url: string, data: any, config: AxiosRequestConfig = {}) => {
  return request("POST", url, data, config);
};

const put = (url: string, data: any, config: AxiosRequestConfig = {}) => {
  return request("PUT", url, data, config);
};

const patch = (url: string, data: any, config: AxiosRequestConfig = {}) => {
  return request("PATCH", url, data, config);
};

const remove = (url: string, config: AxiosRequestConfig = {}) => {
  return request("DELETE", url, {}, config);
};

// Server-Sent Events
const sse = (url: string, method: Method, payload: any = {}): SSE => {
  const token = getAuthToken() || process.env.TEST_AUTH_COOKIE || "";
  const source = new SSE(`${serviceBasePath}${url}`, {
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${token}`,
    },
    method,
    payload: JSON.stringify(payload),
  });
  return source;
};

// SWR shim which resolves the response data
// for the caller
const getJSON = async (url: string) => {
  const response = await get(url);
  return response.data;
};

export const ApiService = { get, post, put, patch, remove, sse, request, getJSON };
