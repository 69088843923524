// Analytics

// Historically, we've used a big pile of analytics apps to hope that within
// that we're capturing the data we need. We had Segment which sent to several
// places, Google Analytics (direct, for ad tracking), and Posthog.
// We're going to be switching almost entirely to PostHog going forward.
// Because of event-autocapture, tagging events like this is almost entirely
// unnecessary. We will still need to ID users etc, and to send events with
//  properties of anything special.

import { useRouter } from "next/router";
import posthog from "posthog-js";
import { useEffect } from "react";
// Segment helper functions cribbed from https://ashokraju.medium.com/using-segment-io-analytics-js-with-single-page-react-typescript-app-a8c12b4816c4

// https://www.typescriptlang.org/docs/handbook/triple-slash-directives.html is a thing
/// <reference types="@types/segment-analytics" />

// Constants to follow our object-action-properties naming convention
// https://humanloop.notion.site/Analytics-Naming-Convention-c3409da767c64a4497d6eb213e3a446e

//Typescript is unaware of the analytics property on window. To fix this, we declare a global by extending window:
declare global {
  interface Window {
    analytics: SegmentAnalytics.AnalyticsJS;
  }
}

export const useAnalytics = () => {
  const router = useRouter();
  useEffect(() => {
    // Init PostHog
    posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY, {
      // e.humanloop.com is a CNAME to app.posthog.com, acting as a proxy so we don't lose data to ad blockers.
      api_host: "https://e.humanloop.com",
      loaded: (posthog) => {
        if (process.env.NODE_ENV === "development") posthog.opt_out_capturing();
      },
    });
    // Track page views
    const handleRouteChange = () => page();
    router.events.on("routeChangeComplete", handleRouteChange);
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export enum EventObject {
  // Preferably use the semantic group if available and fallback
  // to the literal objects (e.g. Button) if that has its challenges.
  AggregateResults = "Aggregate Results",
  Button = "Button",
  CodeEditor = "Code Editor",
  Demo = "Demo",
  Debug = "Debug",
  Datapoint = "Datapoint",
  Dataset = "Dataset",
  File = "File",
  Filter = "Filter",
  Function = "Function",
  GroundTruth = "Ground Truth",
  Page = "Page",
  Pane = "Pane",
  Project = "Project",
  SpanPopover = "Span Popover",
  Template = "Template",
  User = "User",
}

export enum EventAction {
  Changed = "changed",
  Clicked = "clicked",
  Created = "created",
  Disabled = "disabled",
  Enabled = "enabled",
  Exported = "exported",
  Invited = "invited",
  Resized = "resized",
  Run = "run",
  Saved = "saved",
  Scrolled = "scrolled",
  Scheduled = "scheduled",
  Searched = "searched",
  Selected = "selected",
  Sorted = "sorted",
  Validated = "validated",
  Viewed = "viewed",
}

const APPLICATION_CONTEXT = "platform";

export const identify = (userId: string, traits: any) => {
  window?.analytics?.identify(userId, { application_context: APPLICATION_CONTEXT, ...traits });
  // PostHog
  posthog.identify(userId, {
    application_context: APPLICATION_CONTEXT,
    ...traits,
  });
  // TODO: Add a posthog.group call to associate the user with the organization, once we have orgs.
};

export const page = (
  // Page call will include other helpful properties automatically like url, title, referrer.
  category?: string,
  name?: string,
  properties?: Record<string, any>
) => {
  window?.analytics?.page(category, name, {
    application_context: APPLICATION_CONTEXT,
    ...properties,
  });
  posthog.capture("$pageview");
};

const track = (eventObject: EventObject, action: EventAction, properties?: Record<string, any>) => {
  window?.analytics?.track(`${eventObject} ${action}`, {
    object: eventObject,
    action,
    application_context: APPLICATION_CONTEXT,
    ...properties,
  });
  // PostHog
  posthog.capture(`${eventObject} ${action}`, {
    object: eventObject,
    action,
    application_context: APPLICATION_CONTEXT,
    ...properties,
  });
};

export const reset = () => {
  window?.analytics?.reset();
  // PostHog
  // This is __loaded check is because posthog might be called before initialisation.
  // It might only be needed as a short term fix – the posthog team are aware.
  if (posthog.__loaded) posthog.reset();
};
export const Analytics = {
  identify,
  page,
  track,
  reset,
};
