/* eslint-disable no-restricted-globals */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */

(window ).__rewriteFramesAssetPrefixPath__ = '';

import { GLOBAL_OBJ } from '@sentry/utils';

/* eslint-disable no-constant-condition */

const globalObj = GLOBAL_OBJ ;

globalObj.SENTRY_RELEASE = { id: '754ddddf8e2905fd4357c21ba7ad20f5364f3b3f' };

// Enable module federation support (see https://github.com/getsentry/sentry-webpack-plugin/pull/307)
if ('frontend') {
  const key = 'humanloop' ? 'frontend@humanloop' : 'frontend';
  globalObj.SENTRY_RELEASES = globalObj.SENTRY_RELEASES || {};
  globalObj.SENTRY_RELEASES[key] = { id: '754ddddf8e2905fd4357c21ba7ad20f5364f3b3f' };
}

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs";
import Cohere from "cohere-js";
import CohereSentry from "cohere-sentry";
import posthog from "posthog-js";

const DEV = process.env.NODE_ENV === "development";
const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN || "https://93cd414cbd1e4ed9b124778a8c81b2e4@o427213.ingest.sentry.io/5370964";
const SENTRY_ORG_SLUG = process.env.SENTRY_ORG_SLUG || process.env.NEXT_PUBLIC_SENTRY_ORG_SLUG;
const COHERE_API_KEY = process.env.NEXT_PUBLIC_COHERE_API_KEY;
if (COHERE_API_KEY) {
  Cohere.init(COHERE_API_KEY, { segmentIntegration: true });
}

Sentry.init({
  dsn: DEV ? null : SENTRY_DSN,
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps

  // Sample transactions for performance profiling
  tracesSampleRate: 0.2,

  // Ignoring errors that are from extensions and 3rd party scripts, copied from https://docs.sentry.io/platforms/javascript/configuration/filtering/
  ignoreErrors: [
    // Random plugins/extensions
    "top.GLOBALS",
    // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
    "originalCreateNotification",
    "canvas.contentDocument",
    "MyApp_RemoveAllHighlights",
    "http://tt.epicplay.com",
    "Can't find variable: ZiteReader",
    "jigsaw is not defined",
    "ComboSearch is not defined",
    "http://loading.retry.widdit.com/",
    "atomicFindClose",
    // Facebook borked
    "fb_xd_fragment",
    // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
    // reduce this. (thanks @acdha)
    // See http://stackoverflow.com/questions/4113268
    "bmi_SafeAddOnload",
    "EBCallBackMessageReceived",
    // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
    "conduitPage",
  ],
  denyUrls: [
    // Facebook flakiness
    /graph\.facebook\.com/i,
    // Facebook blocked
    /connect\.facebook\.net\/en_US\/all\.js/i,
    // Woopra flakiness
    /eatdifferent\.com\.woopra-ns\.com/i,
    /static\.woopra\.com\/js\/woopra\.js/i,
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
    // Other plugins
    /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
    /webappstoolbarba\.texthelp\.com\//i,
    /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
  ],
  integrations: [new CohereSentry(), new posthog.SentryIntegration(posthog, "humanloop", "5370964")],
});
