import React, { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { ConfirmOptions } from "context/useConfirm";
import Button from "@library/Button";

interface Props {
  options: ConfirmOptions;
  open: boolean;
  onCancel: () => void;
  onConfirm: () => void;
}

export const ConfirmationDialog = ({ options, open, onCancel, onConfirm }: Props) => {
  return (
    <Transition appear show={open} as={Fragment}>
      {/* Antd drawer has z-index of a 1000 🤦‍♂️ */}
      <Dialog as="div" className="fixed inset-0 z-[1010] overflow-y-auto" onClose={onCancel}>
        <div className="min-h-screen px-16 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-100"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black/20" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="inline-block h-screen align-middle" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-100"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-100"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block w-full max-w-lg transform overflow-hidden rounded border bg-white p-32 text-left align-middle shadow-c transition-all">
              <Dialog.Title className="font-medium text-lg leading-6 text-gray-900">{options.title}</Dialog.Title>
              {options.description && <Dialog.Description>{options.description}</Dialog.Description>}
              {options.content && (
                <div className="mt-12">
                  <p className="text-sm text-gray-600">{options.content}</p>
                </div>
              )}

              <div className="mt-16 -mb-12 flex justify-end gap-10">
                <Button onClick={onCancel} {...options.cancelButtonProps}>
                  {options.cancellationText}
                </Button>
                <Button onClick={onConfirm} {...options.confirmButtonProps}>
                  {options.confirmationText}
                </Button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};
