// Based on https://github.com/jonatanklosko/material-ui-confirm
// Differs from the library (as of v2.1.4) in that closing the dialog is equivalent to cancel.
// This avoids leaving the promise hanging. See: https://github.com/jonatanklosko/material-ui-confirm/issues/25
/* Example usage:
const Item = () => {
  const confirm = useConfirm();

  const handleClick = () => {
    confirm({
    title: "Are you sure you want to delete this dataset?",
    content: "This action cannot be undone.",
    confirmationText: "Delete",
    cancellationText: "Cancel",
    })
      .then(() => { ... })
      .catch(() => { ... });
  };

  return (
    <Button onClick={handleClick}>
      Click
    </Button>
  );
};
*/

import { ButtonProps } from "@library/Button";
import { ConfirmationDialog } from "components/atoms/ConfirmationDialog";
import React, { createContext, useCallback, useContext, useState } from "react";

export interface ConfirmOptions {
  title: string;
  description?: string;
  content?: React.ReactNode;
  confirmationText: string;
  cancellationText: string;
  cancelButtonProps?: Omit<ButtonProps, "onClick">;
  confirmButtonProps?: Omit<ButtonProps, "onClick">;
}

const DEFAULT_CONFIRM_OPTIONS: ConfirmOptions = {
  title: "Are you sure?",
  confirmationText: "Ok",
  cancellationText: "Cancel",
  confirmButtonProps: { styling: "solid" },
  cancelButtonProps: {},
};

export type ConfirmType = (options: ConfirmOptions) => Promise<unknown>;

const ConfirmContext = createContext<ConfirmType>(undefined);

interface ResolveReject {
  resolve: () => void;
  reject: () => void;
}

export const ConfirmProvider = ({ children }: { children: React.ReactNode }) => {
  const [options, setOptions] = useState<ConfirmOptions>(DEFAULT_CONFIRM_OPTIONS);
  const [resolveReject, setResolveReject] = useState<ResolveReject | null>(null);
  const { resolve, reject } = resolveReject || {};

  const confirm = useCallback((options: ConfirmOptions) => {
    return new Promise<void>((resolve, reject) => {
      setOptions({ ...DEFAULT_CONFIRM_OPTIONS, ...options });
      setResolveReject({ resolve, reject });
    });
  }, []);

  const handleClose = useCallback(() => {
    setResolveReject(null);
  }, []);

  const handleCancel = useCallback(() => {
    if (reject) {
      reject();
      handleClose();
    }
  }, [reject, handleClose]);

  const handleConfirm = useCallback(() => {
    if (resolve) {
      resolve();
      handleClose();
    }
  }, [resolve, handleClose]);

  return (
    <>
      <ConfirmContext.Provider value={confirm}>{children}</ConfirmContext.Provider>
      <ConfirmationDialog open={resolveReject !== null} options={options} onCancel={handleCancel} onConfirm={handleConfirm} />
    </>
  );
};

const useConfirm = () => {
  const context = useContext(ConfirmContext);
  if (context === undefined) {
    throw new Error("useConfirm must be used within a ConfirmProvider");
  }
  return context;
};

export default useConfirm;
