// Based on https://usehooks-ts.com/react-hook/use-local-storage
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from "react";
import { useEventCallback, useEventListener } from "usehooks-ts";

type SetValue<T> = Dispatch<SetStateAction<T>>;

const customLocalStorageEventName = "hl-local-storage";

function useLocalStorage<T>(key: string, initialValue: T): [T, SetValue<T>, () => void] {
  // Get from local storage then parse stored json or return initialValue.
  const readValue = useCallback((): T => {
    // Prevent build error "window is undefined" but keeps working.
    if (typeof window === "undefined") {
      return initialValue;
    }

    try {
      const item = window.localStorage.getItem(key);
      return item ? (parseJSON(item) as T) : initialValue;
    } catch (error) {
      console.warn(`Error reading localStorage key “${key}”:`, error);
      return initialValue;
    }
  }, [initialValue, key]);

  // State to store our value.
  // Pass initial state function to useState so logic is only executed once.
  const [storedValue, setStoredValue] = useState<T>(readValue);

  // Return a wrapped version of useState's setter function that persists the new value to localStorage.
  const setValue: SetValue<T> = useEventCallback((value) => {
    // Prevent build error "window is undefined" but keeps working.
    if (typeof window === "undefined") {
      console.warn(`Tried setting localStorage key “${key}” even though environment is not a client`);
    }

    try {
      // Allow value to be a function so we have the same API as useState.
      const newValue = value instanceof Function ? value(storedValue) : value;

      // Save to local storage.
      window.localStorage.setItem(key, JSON.stringify(newValue));

      // Save state.
      setStoredValue(newValue);

      // We dispatch a custom event so every useLocalStorage hook are notified.
      window.dispatchEvent(new Event(customLocalStorageEventName));
    } catch (error) {
      console.warn(`Error setting localStorage key “${key}”:`, error);
    }
  });

  useEffect(() => {
    setStoredValue(readValue());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleStorageChange = useCallback(
    (event: StorageEvent | CustomEvent) => {
      if ((event as StorageEvent)?.key && (event as StorageEvent).key !== key) {
        return;
      }
      setStoredValue(readValue());
    },
    [key, readValue]
  );

  // This only works for other documents, not the current one.
  useEventListener("storage", handleStorageChange);

  // This is a custom event, triggered in writeValueToLocalStorage.
  useEventListener(customLocalStorageEventName as any, handleStorageChange);

  const clearValue = useCallback(() => {
    if (typeof window !== "undefined") {
      window.localStorage.removeItem(key);
      window.dispatchEvent(new Event(customLocalStorageEventName));
    }
  }, [key]);

  return [storedValue, setValue, clearValue];
}

export default useLocalStorage;

// A wrapper for "JSON.parse()"" to support "undefined" value
function parseJSON<T>(value: string | null): T | undefined {
  try {
    return value === "undefined" ? undefined : JSON.parse(value ?? "");
  } catch (error) {
    console.log("parsing error on", { value });
    return undefined;
  }
}

export const getLocalStorage = <T>(key: string): T | null => {
  if (typeof window === "undefined") {
    return null;
  }
  try {
    const item = window.localStorage.getItem(key);
    return item ? (parseJSON(item) as T) : null;
  } catch (error) {
    console.warn(`Error reading localStorage key “${key}”:`, error);
    return null;
  }
};
