import { DisplayTypes, DataTypes, TaskTypes } from "types/project";

export const DATATYPES_USABLE_FOR_MODEL = [DisplayTypes.text, DisplayTypes.title, DisplayTypes.pdf];

export const TASKSTRINGS = {
  [TaskTypes.classification]: "classification",
  [TaskTypes.multilabel]: "multilabel classification",
  [TaskTypes.span_tagging]: "span tagging",
  [TaskTypes.ordinal_regression]: "ordinal regression",
};

export const DATA_TO_TASK = {
  [DataTypes.categorical]: TaskTypes.classification,
  [DataTypes.multi_categorical]: TaskTypes.multilabel,
  [DataTypes.character_offsets]: TaskTypes.span_tagging,
  [DataTypes.ordinal_regression]: TaskTypes.ordinal_regression,
};

export const DATA_TO_DEFAULT_NAME = {
  // We used to put 'new_' in front of some of these but decided it was cleaner without
  [DataTypes.text]: "text",
  [DataTypes.categorical]: "categorisation",
  [DataTypes.multi_categorical]: "categorisation",
  [DataTypes.character_offsets]: "span_tagging",
  [DataTypes.ordinal_regression]: "score",
};

export const INPUT_DATA_TYPES = [DataTypes.text, DataTypes.url, DataTypes.number, DataTypes.file_pdf];

export const OUTPUT_DATA_TYPES = [
  DataTypes.categorical,
  DataTypes.multi_categorical,
  DataTypes.character_offsets,
  DataTypes.quantitative,
  DataTypes.ordinal_regression,
];

// Options for the high-level display types the user selects when picking fields from their dataset
export const DISPLAY_AS_TYPES = {
  [DisplayTypes.text]: {
    dataType: DataTypes.text,
    displayOnly: false,
  },
  [DisplayTypes.title]: {
    dataType: DataTypes.text,
    displayOnly: false,
  },
  [DisplayTypes.meta]: {
    dataType: DataTypes.text,
    displayOnly: true,
  },
  // [DisplayTypes.image]: {
  //   dataType: DataTypes.url,
  //   displayOnly: true,
  // },
  [DisplayTypes.link]: {
    dataType: DataTypes.url,
    displayOnly: true,
  },
  [DisplayTypes.pdf]: {
    dataType: DataTypes.file_pdf,
    displayOnly: false,
  },
};

// At some point this will be more sophisiticated, e.g. a URL maps to a Link, text to text etc. For now we start all as display as text.
export const DEFAULT_DISPLAY_AS_TYPE = DisplayTypes.text;

// We don't actually use these two right now
export const HIDDEN_DISPLAY_TYPES = [DisplayTypes.image];

export const WORK_IN_PROGRESS_PROJECT_LOCALSTORAGE_KEY = "work-in-progress-project";

// Regular space. Unicode character 20 (aka \x20)
export const SPACE = " ";

// This is not a space character. It is Unicode character c2a0 (aka \xc2\xa0).
// See encodeURI() output
// Check it at https://www.browserling.com/tools/utf8-encode
export const NON_BREAKING_SPACE = " ";

export const ONLY_ONE_INPUT_PER_PROJECT = 0;
export const ONLY_ONE_OUTPUT_PER_PROJECT = 0;
export const INPUT_IS_ASSUMED_TEXT = null;

// If this in the URL the user can signup without having to book a demo
export const SKIP_DEMO_CALL = "immediate";

export const NEXT_TICK = 0;

export const NOT_FOUND = -1;

// Instructions are only a single line,
// longer information should be in guidelines instead
export const MAXIMUM_INSTRUCTIONS_LENGTH = 280;

// Labels can extend up to this amount of pixels beyond the last rectangle of their spans
export const LABELS_EXTEND_BEYOND_SPANS = 100;

export const EMPTY = "";

export const SECOND = 1000;

export const FEATURES = {
  // Whether to show label descriptions - not all parts of our UI have
  // these right now
  SHOW_LABEL_DESCRIPTIONS: false,

  // Warn for non-blink browsers (eg, not Chrome, not Edge)
  WARN_OTHER_BROWSERS: false,
};

export const SPAN_SUMMARY_CONTEXT_CHARACTER_LENGTH = 100;

export const DEFAULT_ANNOTATOR_COUNT = 2;

export const PARSED_TEXT_SUFFIX = "_parsed_text";

export const PDF_WIDTH = (595 * 96) / 72;
export const PDF_HEIGHT = (842 * 96) / 72;

// Placeholder value required even when disabling hotkeys if shortcut is not set
// (undefined, empty string or null) it seems to stop all other hotkeys from working.
// This variable should always be used alongside {enabled: false}.
// This results in: 1. the callback being properly unbound, and 2. other hotkeys continue functioning.
export const PLACEHOLDER_HOTKEY = "num_add";

export const API_URL = "https://api.humanloop.com"; // NB: best to use NEXT_PUBLIC_API_URI for the server setting.
export const DOCS_LINK = "https://docs.humanloop.com/";
export const DOCS_CREATE_PREDICTION = "https://docs.humanloop.com/api/create-prediction";
export const PROGRAMMATIC_LINK = "https://programmatic.humanloop.com/";
export const PROGRAMMATIC_DOCS_LINK = "https://docs.programmatic.humanloop.com/";
export const SLACK_LINK = "https://join.slack.com/t/humanloop-community/shared_invite/zt-15bpaukat-w99Ns5wyQiuKVPSxyLqHlQ";
export const ZAPIER_LINK = "https://zapier.com/apps/humanloop/integrations";

export const LLAMA_DOCS = "https://humanloop.readme.io/docs";

export const PLACEHOLDER_DASHES = "– –";

export const UNSET_FEEDBACK = "__UNSET__";
