import { configureStore } from "@reduxjs/toolkit";
import tasks from "./tasks";

const store = configureStore({
  reducer: {
    tasks: tasks.reducer,
  },
  // middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(createSentryMiddleware(Sentry)),
});

export type RootState = ReturnType<typeof store.getState>;
export default store;
