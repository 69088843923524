// Ant design then Tailwind and
// custom, imported last so we can overwrite CSS set elsewhere.
// import "antd/dist/antd.css"; // Untouched antd (not used as global styles)
// Preflight is a custom CSS reset that should go first
import "../styles/preflight.css";
// Ant design (fyi we want to get rid of this!) next before tailwind
import "../styles/antd.less"; // TODO: remove ANTD
import "tippy.js/dist/tippy.css";
// The main Tailwind CSS file
import "../styles/index.css";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";

import "@fontsource/rubik/400.css";
import "@fontsource/rubik/500.css";

import DayjsConfig from "@components/llama/DayjsConfig";
import { HumanloopToaster } from "@library/Toast";
import { ProvideDebug } from "context/debug-context";
import { useAnalytics } from "lib/analytics";
import { ProvideAuth } from "lib/use-auth";
import { Provider } from "react-redux";
import { ApiService } from "services/api.service";
import { SWRConfig } from "swr";
import { AppErrorBoundary } from "../components/error/AppErrorBoundary";
import { ConfirmProvider } from "../context/useConfirm";
import store from "../store/main";

function Humanloop({ Component, pageProps, err }) {
  const getLayout = Component.getLayout || ((page) => page);
  useAnalytics();
  return (
    <Provider store={store}>
      <SWRConfig value={{ fetcher: ApiService.getJSON }}>
        <ProvideDebug>
          <ProvideAuth>
            {/* Generic Error boundary reporting to capture full app crashes. 
            I can't figure out a way to prefill user details if we have them but that would be preferable. */}
            <AppErrorBoundary>
              <ConfirmProvider>
                <DayjsConfig />
                {getLayout(<Component {...pageProps} />)}
                <HumanloopToaster />
              </ConfirmProvider>
            </AppErrorBoundary>
          </ProvideAuth>
        </ProvideDebug>
      </SWRConfig>
    </Provider>
  );
}
export default Humanloop;
