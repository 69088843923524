import * as Sentry from "@sentry/nextjs";
import Cohere from "cohere-js";
import { Analytics, EventAction, EventObject } from "lib/analytics";
import { useRouter } from "next/router";
import React, { createContext, useCallback, useContext, useEffect } from "react";
import { useDispatch } from "react-redux";
import { ApiService } from "services/api.service";
import { CurrentUser, verifyUser, VerifyUserRequest } from "services/users.service";
import useSWR from "swr";
import useLocalStorage, { getLocalStorage } from "./use-local-storage";

export const AUTH_STORAGE = "Authorization";
const AUTH_EXPIRY = 1000 * 60 * 60 * 24 * 7; // Expiry in 7 days

interface AuthContextInterface {
  user: any;
  login: (username: string, password: string) => Promise<any>;
  logout: () => void;
  forgot: (username: string) => Promise<any>;
  reset: (password: string, token: string) => Promise<any>;
  signup: (signUpData: any) => Promise<any>;
  verifyAndLogin: (request: VerifyUserRequest) => Promise<any>;
  authToken: string | null;
  loading: boolean;
  llamaUser: CurrentUser;
}

const AuthContext = createContext<AuthContextInterface | undefined>(undefined);

// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export function ProvideAuth({ children }: React.PropsWithChildren<unknown>): JSX.Element {
  const auth = useProvideAuth();
  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
}

// Hook for child components to get the auth object and re-render when it changes.
export const useAuth = (): AuthContextInterface => {
  return useContext(AuthContext);
};

// As above but will redirect if auth has loaded and you're not logged in.
export function useRequireAuth(redirectUrl = "/login"): AuthContextInterface {
  const auth = useAuth();
  const router = useRouter();

  // If auth.user is false that means we're not
  // logged in and should redirect.
  useEffect(() => {
    if (!auth.user && !auth.loading) {
      router.replace(`${redirectUrl}?next=${router.asPath}`);
    }
  }, [auth, router, redirectUrl]);

  useEffect(() => {
    if (
      auth.user?.feature_flags &&
      "LLAMA" in auth.user.feature_flags &&
      !router.asPath.includes("llama") &&
      !router.asPath.includes("/playground") &&
      !router.asPath.includes("/signup")
    ) {
      console.log("Redirecting to /llama due to authentication.");
      router.push("/llama");
    }
  }, [auth.user?.feature_flags, router]);

  return auth;
}

const setAnalyticsContext = (user = null) => {
  // Send the user ID to Segment and other analytics tools.
  if (user) {
    // See https://segment.com/docs/connections/spec/identify/
    Analytics.identify(user.id, { ...user, email: user.email_address, name: user.full_name });
    // It should be the case that Sentry, Cohere, FullStory etc. all get the id information from Segment.
    // However, in practice, I'm not convinced Sentry is getting it (potentially Sentry being intialised first), so...
    Sentry.setUser(user);
    // Similarly, Segment *should* send the identifier to Cohere, but only if
    // Segment is initialized beforehand... and it appears that it doesn't, so for now we have cohere
    // using the segment integration but we still identify directly to them too.
    // see https://docs.cohere.io/external-integrations/segment
    Cohere.identify(user.id, {
      displayName: user.full_name || user.email_address,
      email: user.email_address,
    });
  } else {
    Analytics.reset();
    Sentry.configureScope((scope) => scope.setUser(null));
  }
};

interface AuthStorage {
  token: string;
  timestamp: number;
}

// Provider hook that creates auth object and handles state
function useProvideAuth(): AuthContextInterface {
  // We don't use internal state, we just use the cached result from SWR.
  // The destructured data property is renamed user
  const [authStorage, setAuthStorage, clearAuthStorage] = useLocalStorage<AuthStorage | null>(AUTH_STORAGE, null);

  const setAuthToken = useCallback(
    (token: string) => {
      setAuthStorage({ token, timestamp: Date.now() });
    },
    [setAuthStorage]
  );

  useEffect(() => {
    if (authStorage && authStorage.timestamp < Date.now() - AUTH_EXPIRY) {
      console.log("clearing auth storage due to expiry");
      clearAuthStorage();
    }
  }, [authStorage, setAuthStorage, clearAuthStorage]);
  const dispatch = useDispatch();

  const { data, error, mutate } = useSWR([`/users/me`, authStorage?.token], {
    //TODO: this was a temp fix to stop the form from breaking on model predict api
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  const { data: llamaUser } = useSWR([`/v3/users/me`, authStorage?.token], {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  useEffect(() => {
    setAnalyticsContext(data);
  }, [data]);

  // Wrap any auth methods we want to use making sure to save the user to state.

  /**
   * Login a user
   * @param {string} username
   * @param {string} password
   * @returns {Promise<AxiosResponse>}
   * @throws {Error} - if the response is not 200
   */
  const login = async (username: string, password: string) => {
    try {
      const params = new URLSearchParams();
      params.append("username", username);
      params.append("password", password);

      const response = await ApiService.post("/token", params);
      const { data } = response;

      setAuthStorage({ token: data.access_token, timestamp: Date.now() });
      // Broadcast a revalidation message globally to all SWRs with this key ('/api/me'), and
      // locally mutate it with the user we've just received.
      mutate(data.user);
      setAnalyticsContext(data.user);
      return response;
    } catch (error /*: AxiosError */) {
      if (error.response?.status === 401) {
        console.log("401 error");
      } else {
        console.log("error", error);
      }
      return error.response;
    }
  };

  const forgot = async (username: string) => {
    try {
      const response = await ApiService.post(`/users/recover/${username}`, undefined);
      const { data } = response;

      mutate(data.user);

      return response;
    } catch (err) {
      return err.response;
    }
  };

  const reset = async (password: string, token: string) => {
    try {
      const response = await ApiService.post(`/users/verify`, { token, password });
      const { data } = response;

      setAuthToken(data.access_token);
      mutate(data.user);

      return response;
    } catch (err) {
      return err.response;
    }
  };

  const verifyAndLogin = useCallback(
    async (request: VerifyUserRequest) => {
      const response = await verifyUser(request);
      const { data } = response;
      setAuthToken(data.access_token);
      mutate(data.user);
      setAnalyticsContext(data.user);
      return response.data;
    },
    [mutate, setAuthToken]
  );

  const signup = async (signupData) => {
    try {
      const response = await ApiService.post("/users", signupData);
      const { data } = response;

      setAuthToken(data.access_token);
      mutate(data.user);
      Analytics.track(EventObject.User, EventAction.Created, { ...data.user, method: "web" });
      setAnalyticsContext(data.user);

      return response;
    } catch (err) {
      return err.response;
    }
  };

  const logout = () => {
    console.log("attempted to remove auth storage");
    clearAuthStorage();
    mutate("");
    dispatch({ type: "global/reset" });
    setAnalyticsContext(null);
    window.location.href = "https://humanloop.com";
  };

  // Return the user object and auth methods
  return {
    user: data,
    login,
    logout,
    forgot,
    signup,
    reset,
    verifyAndLogin,
    authToken: authStorage ? authStorage.token : null,
    loading: !error && !data,
    llamaUser,
  };
}

export const getAuthToken = (): string | null => {
  const authStorage = getLocalStorage<AuthStorage | null>(AUTH_STORAGE);
  if (authStorage) {
    if (authStorage.timestamp < Date.now() - AUTH_EXPIRY) {
      return null;
    }
    return authStorage.token;
  }
  return null;
};
