import { ApiService } from "./api.service";
import { isEmpty } from "lodash";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import tasksSlice from "store/tasks";
import useSWR from "swr";
import { useRouter } from "next/router";
import { getAuthToken } from "lib/use-auth";
import { User } from "types/users";

const TaskApi = {
  getProjectTask: (projectId: number, taskId: number) => {
    return ApiService.get(`/projects/${projectId}/tasks/${taskId}`);
  },

  getTask: (taskId: number) => {
    return ApiService.get(`/tasks/${taskId}`);
  },

  updateTask: (taskId: number, payload) => {
    return ApiService.put(`/tasks/${taskId}`, payload);
  },

  assignUser: (taskId: number, user: User) => {
    return ApiService.put(`/tasks/${taskId}`, { email_address: user.email_address });
  },
};

export const useTasks = (user) => {
  // Fetches the sorted, filted tasks
  const dispatch = useDispatch();
  const router = useRouter();
  const { projectId } = router.query;

  const taskFetchPath = useSelector(tasksSlice.selectors.taskFetchPath);

  const currentPage = parseInt(taskFetchPath.split("page=")?.[1]);

  const sortedFilteredTasks = useSelector(tasksSlice.selectors.sortedFilteredTasks);

  const { data: taskFetch, mutate: mutateTasks, error: taskErrors } = useSWR(user && `/projects/${projectId}/users/${user.id}/${taskFetchPath}`);

  useEffect(() => {
    if (taskFetch?.records?.length > 0) {
      dispatch(tasksSlice.actions.addMany(taskFetch?.records));
      // addMany instead of upsertMany as SWR's cache will mean the upsert overrides modified tasks with stale information.
      // E.g. Task is incomplete when page loads, user completes task, switches to a different filter (such as "Flagged tasks") and switches back to "Unlabelled".
      //      The newly-completed task will reappear due to SWR's cache meaning this upsert overrides the new data (i.e. complete status) with stale data (i.e. incomplete status).
      if (!isNaN(currentPage)) {
        dispatch(tasksSlice.actions.setMaxPageCeiling({ maxPage: currentPage + 1 }));
      }
    } else if (taskFetch && taskFetch?.records?.length === 0) {
      if (!isNaN(currentPage)) {
        dispatch(tasksSlice.actions.setMaxPage({ maxPage: currentPage - 1 }));
      }
    }
  }, [currentPage, dispatch, taskFetch]);

  useEffect(() => {
    if (sortedFilteredTasks?.length < 16) {
      mutateTasks();
    }
  }, [mutateTasks, sortedFilteredTasks?.length]);

  const currentTask = useSelector(tasksSlice.selectors.currentTask);

  useEffect(() => {
    if (isEmpty(currentTask) && sortedFilteredTasks[0]?.id) {
      dispatch(tasksSlice.actions.setCurrent({ taskId: sortedFilteredTasks[0]?.id }));
    }
  }, [currentTask, dispatch, sortedFilteredTasks]);

  return { tasks: sortedFilteredTasks, mutateTasks, taskErrors, pageLoading: !taskFetch && !taskErrors, currentLoading: !currentTask };
};

export const useProjectTasks = ({ projectId }, swrOptions = {}) => {
  const { data, error, mutate } = useSWR(projectId ? [`/projects/${projectId}/tasks`, getAuthToken()] : null, swrOptions);
  return { data, mutate, loading: !error && !data, error };
};

export default TaskApi;
