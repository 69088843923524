// [Slice](https://en.wikipedia.org/wiki/Program_slicing) for all the annotation state stuff
// We can mutate the state thanks to various helpers in redux toolkit and it will still operate as immuateble
// Note that all 'state' here refers only to local state of the slice (that within 'tasks')

import { createAsyncThunk, createEntityAdapter, createSelector, createSlice, EntityState } from "@reduxjs/toolkit";
import * as Sentry from "@sentry/nextjs";
import { notification } from "antd";
import Cohere from "cohere-js";
import { ONLY_ONE_OUTPUT_PER_PROJECT } from "lib/constants";
import { isEmpty } from "lodash";
import React from "react";
import TaskApi from "services/tasks.service";
import AnnotationApi from "services/annotations.service";
import { generateId, ObjectTypes } from "lib/utils";
import dayjs from "dayjs";
import { Task, TaskResponse } from "types/tasks";
import { User } from "types/users";
import { ProjectInput, ProjectOutput } from "types/project";
import { Annotation, ClassificationAnnotation } from "types/annotations";
import { RootState } from "./main";

const SLICE_NAME = "tasks";

export enum VisibilityFilters {
  SHOW_ALL = "all",
  SHOW_ACTIVE = "unlabeled",
  SHOW_COMPLETED = "completed",
  SHOW_FLAGGED = "flagged",
}

export enum SortingOptions {
  CONFUSION = "confusion",
  CONFIDENCE = "confidence",
  LAST_UPDATED = "last_updated",
}

interface TasksState {
  sortings: any;
  filter: VisibilityFilters;
  keywords: string;
  debouncedSearchTerm: string;
  current: number | null; // The current task id.
  currentTask: Task | null;
  previous: number[];
  inputs: ProjectInput[];
  outputDef: ProjectOutput | null;
  unlabeledSorting: null;
  labeledSorting: null;
  page: number;
  maxPage: number;
  loading: boolean;
}

const tasksAdapter = createEntityAdapter<Task>();

type TasksStateSliceWithEntities = TasksState & EntityState<Task>;

const initialState: TasksStateSliceWithEntities = {
  ...tasksAdapter.getInitialState(),
  sortings: {
    [VisibilityFilters.SHOW_ALL]: SortingOptions.CONFUSION,
    [VisibilityFilters.SHOW_ACTIVE]: SortingOptions.CONFUSION,
    [VisibilityFilters.SHOW_COMPLETED]: SortingOptions.LAST_UPDATED,
    [VisibilityFilters.SHOW_FLAGGED]: SortingOptions.LAST_UPDATED,
  },
  filter: VisibilityFilters.SHOW_ACTIVE,
  keywords: "",
  debouncedSearchTerm: "",
  current: null, // The current task id.
  currentTask: null,
  previous: [],
  inputs: [],
  outputDef: null,
  unlabeledSorting: null,
  labeledSorting: null,
  page: 0,
  maxPage: 0,
  loading: false,
};

const tasksSelectors = tasksAdapter.getSelectors();
const allTasks = (state) => tasksSelectors.selectAll(state);
const filter = (state) => state.filter;
const sortings = (state) => state.sortings;
const inputs = (state) => state.inputs;
const outputDef = (state) => state.outputDef;
const sorting = (state) => state.sortings[state.filter];
const keywords = (state) => state.keywords;
const page = (state) => state.page;
const maxPage = (state) => state.maxPage;
const debouncedSearchTerm = (state) => state.debouncedSearchTerm;

const filteredTasks = createSelector<any, Task[]>([allTasks, filter], (tasks: Task[], filter: VisibilityFilters) => {
  switch (filter) {
    case VisibilityFilters.SHOW_ALL:
      return tasks;
    case VisibilityFilters.SHOW_COMPLETED:
      return tasks.filter((t) => t.complete);
    case VisibilityFilters.SHOW_ACTIVE:
      return tasks.filter((t) => !t.complete && !t.flagged);
    case VisibilityFilters.SHOW_FLAGGED:
      return tasks.filter((t) => t.flagged);
    default:
      throw new Error("Unknown filter: " + filter);
  }
});
const sortedFilteredTasks = createSelector<any, Task[]>([filteredTasks, sorting], (tasks: Task[], sorting: SortingOptions) => {
  switch (sorting) {
    case SortingOptions.CONFUSION:
      return tasks.sort((a, b) => b.score - a.score);
    case SortingOptions.CONFIDENCE:
      return tasks.sort((a, b) => a.score - b.score);
    default:
    case SortingOptions.LAST_UPDATED:
      return tasks.sort((a, b) => (dayjs(a.updated_at).isAfter(dayjs(b.updated_at)) ? -1 : 1));
  }
});

const visibleTasks = createSelector<any, Task[]>([sortedFilteredTasks, keywords, inputs], (tasks: Task[], keywords: string) => {
  if (keywords) {
    return tasks.filter((task) => Object.values(task?.inputs)?.some((val) => val.toLowerCase().includes(keywords.toLowerCase())));
  } else return tasks;
});

// Reminder that these selectors are operating on the slice of state too (e.g. globalState.tasks). However when we export them they
// are wrapped to operate on the global state object.
const selectors = {
  allTasks,
  filteredTasks,
  sortedFilteredTasks,
  visibleTasks,
  keywords,
  debouncedSearchTerm,
  sorting,
  sortings,
  inputs,
  outputDef,
  page,
  maxPage,
  ids: (state) => tasksSelectors.selectIds(state),
  entities: (state) => tasksSelectors.selectEntities(state),
  total: (state) => tasksSelectors.selectTotal(state),
  selectById: (id) => (state) => tasksSelectors.selectById(state, id),
  filter: (state) => state.filter,
  loading: (state) => state.loading,
  current: (state) => state.current,
  currentTask: (state) => state.entities[state.current],
  taskFetchPath: (state) =>
    `tasks?${state.filter === VisibilityFilters.SHOW_COMPLETED ? "&complete=true" : ""}${
      state.filter === VisibilityFilters.SHOW_ACTIVE ? "&complete=false&flagged=false" : ""
    }${state.filter === VisibilityFilters.SHOW_FLAGGED ? "&flagged=true" : ""}&search_keywords=${state.debouncedSearchTerm}&sortby=${
      state.sortings[state.filter]
    }&size=100&page=${state.page}`,
};

const getTask = async ({ projectId, taskId }: { projectId: number; taskId: number }) => {
  return (await TaskApi.getProjectTask(projectId, taskId)).data;
};

/* ACTIONS */

const fetchById = createAsyncThunk("tasks/fetchById", async ({ projectId, taskId }: { projectId: number; taskId: number }, thunkAPI) =>
  getTask({ projectId, taskId })
);

const flag = createAsyncThunk("tasks/task/flag", async ({ task }: { task: Task }, { getState, dispatch }) => {
  const response = await TaskApi.updateTask(task.id, { flagged: !task.flagged });
  return response.data;
});
const assignUser = createAsyncThunk("tasks/assignUser", async ({ taskId, user }: { taskId: number; user: User }, { getState, dispatch }) => {
  // TODO: this could likely be in the /task format of all the others, however it's only being called by
  // the task table view at the moment which isn't using the redux store. So this is a quick verison just
  // using taskId for now so we  don't automatically trigger the store update on pending etc.
  const response = await TaskApi.assignUser(taskId, user);
  return response.data;
});

/* Used by single label classification */
const setUniqueAnnotationValue = createAsyncThunk(
  "tasks/task/setUniqueAnnotationValue",
  async ({ task, output, value }: { task: Task; output: ProjectOutput; value: { label: string | null; maybeId: string } }, { rejectWithValue }) => {
    const labelIds = new Set(output.labels.map((l) => l.id));
    const labelsByName = output.labels.reduce((acc, l) => ({ ...acc, [l.name]: l }), {});
    const annotation = task.annotations?.find((ann) => labelIds.has(ann.label_id));
    const label = labelsByName[value.label];

    let upsertedAnnotation = {
      id: annotation?.id || value.maybeId || generateId(ObjectTypes.Annotation),
      task_id: task.id,
      label_id: label.id,
    };

    try {
      const response = await AnnotationApi.upsertAnnotation({ annotation: upsertedAnnotation });
      upsertedAnnotation = response.data;
    } catch (err) {
      const { response } = err;

      if ([404, 422].includes(response.status) && task?.project_id && task?.id) {
        console.log("Task annotation state is stale, refetching...");
        return getTask({ projectId: task.project_id, taskId: task.id });
      } else {
        throw rejectWithValue(response.data);
      }
    }

    return { id: task.id, annotations: [upsertedAnnotation] };
  }
);

/* Used by ordinal regression */
const upsertAnnotationStrength = createAsyncThunk(
  "tasks/task/upsertAnnotationStrength",
  async ({ task, output, labelName, strength }: { task: Task; output: ProjectOutput; labelName: string; strength: number }, { rejectWithValue }) => {
    const label = output.labels.find((l) => l.name === labelName);
    const annotation = task.annotations?.find((ann) => ann.label_id === label.id);
    const otherAnnotations = task.annotations?.filter((ann) => ann.label_id !== label.id) || [];

    let newAnnotation;

    if (annotation) {
      let response;
      try {
        response = await AnnotationApi.updateAnnotation({ annotationId: annotation.id, strength: strength });
        newAnnotation = response.data;
      } catch (err) {
        if ([404, 422].includes(response.status) && task?.project_id && task?.id) {
          console.log("Task annotation state is stale, refetching...");
          return getTask({ projectId: task.project_id, taskId: task.id });
        } else {
          throw rejectWithValue(err.response.data);
        }
      }
    } else {
      let response;
      try {
        response = await AnnotationApi.createAnnotation({
          annotation: { id: generateId(ObjectTypes.Annotation), task_id: task.id, label_id: label.id, strength: strength },
        });
        newAnnotation = response.data;
      } catch (err) {
        // Backend returns 422 when annotation already exists, so resync task annotation state
        if (response.status === 422 && task?.project_id && task?.id) {
          return getTask({ projectId: task.project_id, taskId: task.id });
        } else {
          throw rejectWithValue(err.response.data);
        }
      }
    }

    return { id: task.id, annotations: [...otherAnnotations, newAnnotation] };
  }
);

/* Used by classification / span tagging */

const addAnnotationValue = createAsyncThunk(
  "tasks/task/addAnnotationValue",
  // TODO: type annotationValue
  async ({ task, output, annotationValue }: { task: TaskResponse; output: ProjectOutput; annotationValue: any }, { getState, dispatch, rejectWithValue }) => {
    const value = task?.annotations ? [...task.annotations, annotationValue] : [annotationValue];
    value.sort((a, b) => a.start - b.start);

    try {
      await AnnotationApi.createAnnotation({ annotation: { ...annotationValue, task_id: task.id } });
      return { id: task.id, annotations: value };
    } catch (err) {
      const { response } = err;
      // Backend returns 422 when annotation already exists, so resync task annotation state
      if (response.status === 422 && task?.project_id && task?.id) {
        return getTask({ projectId: task.project_id, taskId: task.id });
      } else {
        throw rejectWithValue(response.data);
      }
    }
  }
);

const removeAnnotationValue = createAsyncThunk(
  "tasks/task/removeAnnotationValue",
  async ({ task, output, annotationId }: { task: Task; output: ProjectOutput; annotationId: string }, { getState, dispatch }) => {
    try {
      await AnnotationApi.removeAnnotation({ annotationId });
      return { id: task.id };
    } catch (err) {
      const { response } = err;
      // Backend returns 404 when annotation doesn't exist, so resync task annotation state
      if (response.status === 404 && task?.project_id && task?.id) {
        return getTask({ projectId: task.project_id, taskId: task.id });
      } else {
        throw err;
      }
    }
  }
);

const toggleComplete = createAsyncThunk("tasks/task/toggleComplete", async ({ task }: { task: Task }, { getState, dispatch }) => {
  const response = await TaskApi.updateTask(task.id, { complete: !task.complete });
  return response.data;
});
const setComment = createAsyncThunk("tasks/task/setComment", async ({ task, comment }: { task: Task; comment: string }, { getState, dispatch }) => {
  const response = await TaskApi.updateTask(task.id, { comment });
  return response.data;
});

const submit = createAsyncThunk("tasks/task/submit", async ({ task }: { task: Task }, { getState, dispatch }) => {
  const response = await TaskApi.updateTask(task.id, { complete: true });
  return response.data;
});

const skip = createAsyncThunk("tasks/skip", async (_, { getState, dispatch }) => {
  const state = (getState() as any).tasks; // Go from global state to this slice.
  // 'pending' happens first, which calls next() immediately.
  const task = state.entities[state.previous[state.previous.length - 1]];
  const response = await TaskApi.updateTask(task.id, { flagged: true });
  return response.data;
});

const isTaskAction = (status) => (action) => action.type && action.type.startsWith("tasks/task/") && action.type.endsWith(status);

const slice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    reset: () => ({ ...initialState }),
    addOne: tasksAdapter.addOne,
    addMany: tasksAdapter.addMany,
    upsertOne: tasksAdapter.upsertOne,
    upsertMany: tasksAdapter.upsertMany,
    removeOne: tasksAdapter.removeOne,
    setFilter: (state, { payload }) => ({ ...state, filter: payload.filter, page: 0 }),
    nextPage: (state, _) => ({ ...state, page: Math.min(state.page + 1, state.maxPage) }),
    previousPage: (state, _) => ({ ...state, page: state.page - 1 }),
    setMaxPageCeiling: (state, { payload }) => ({ ...state, maxPage: Math.max(payload.maxPage, state.maxPage) }),
    setMaxPage: (state, { payload }) => ({ ...state, maxPage: payload.maxPage }),
    setUnlabeledSorting: (state, { payload }) => ({ ...state, unlabeledSorting: payload.sorting, page: 0 }),
    setLabeledSorting: (state, { payload }) => ({ ...state, labeledSorting: payload.sorting, page: 0 }),
    setKeywords: (state, { payload }) => ({ ...state, keywords: payload.keywords, page: 0 }),
    setDebouncedSearchTerm: (state, { payload }) => ({ ...state, debouncedSearchTerm: payload.debouncedSearchTerm, page: 0 }),
    setSorting: (state, { payload }) => {
      state.sortings[payload.filter] = payload.sorting;
      state.page = 0;
    },
    setProject: (state, { payload }) => {
      // Will set up the input and output defs from a project
      state.inputs = payload?.project?.inputs || [];
      state.outputDef = payload?.project?.outputs?.[ONLY_ONE_OUTPUT_PER_PROJECT];
    },
    setComplete: (state, { payload }) => {
      const task = state.entities[payload.task.id];
      task.complete = true;
    },
    // submit: (state, action) => {
    //   slice.caseReducers.setComplete(state, action);
    //   slice.caseReducers.next(state, action);
    // },
    toggleFlag: (state, { payload }) => {
      const task = state.entities[payload.task.id];
      task.flagged = !task.flagged;
    },
    setCurrent: (state, { payload }) => {
      // Track the IDs in the previous task list
      if (state.current) {
        // TODO: gross type forcing.
        const prevIndex = state.previous.findIndex((previousId) => previousId === state.current);
        if (prevIndex !== -1) {
          state.previous.splice(prevIndex, 1);
        }
        state.previous.push(state.current);
      }
      // Make sure the task is in view.
      state.current = payload.taskId;
      const task = state.entities[payload.taskId];
      if (!sortedFilteredTasks(state).find((t) => t.id == task?.id)) {
        if (task?.flagged) {
          state.filter = VisibilityFilters.SHOW_FLAGGED;
        } else if (task?.complete) {
          state.filter = VisibilityFilters.SHOW_COMPLETED;
        } else if (!task?.complete) {
          state.filter = VisibilityFilters.SHOW_ACTIVE;
        }
      }
    },
    next: (state, action) => {
      const tasks = visibleTasks(state);
      // TODO: The second String is supposedly redundant but it doesn't work without it!
      // Somewhere we're setting current to be an number still.
      const index = tasks.findIndex((task) => task.id === state.current);
      const nextId = tasks[index + 1]?.id || null;
      //  setCurrent so that we can track the previous task
      slice.caseReducers.setCurrent(state, { ...action, payload: { ...action.payload, taskId: nextId } });
    },
    previous: (state) => {
      state.current = state.previous.pop() || null;
    },
    up: (state) => {
      const tasks = visibleTasks(state);
      // This might not be the same as previous as the list may have changed
      const index = tasks.findIndex((task) => task.id === state.current);
      state.current = tasks[index - 1]?.id || null;
    },
    down: (state) => {
      const tasks = visibleTasks(state);

      // This might not be the same as previous as the list may have changed
      const index = tasks.findIndex((task) => task.id === state.current);
      state.current = tasks[index + 1]?.id || null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase("global/reset", (state, action) => slice.caseReducers.reset())
      // SPECIFIC ACTIONS
      .addCase(fetchById.fulfilled, (state, { payload }) => {
        slice.caseReducers.upsertOne(state, payload);
      })
      .addCase(flag.pending, (state, action) => {
        slice.caseReducers.toggleFlag(state, { ...action, payload: action.meta.arg });
      })
      .addCase(skip.pending, (state, action) => {
        const task = state.entities[state.current];
        task.flagged = true;
        slice.caseReducers.next(state, action);
      })
      .addCase(skip.fulfilled, (state, { payload }) => {
        slice.caseReducers.upsertOne(state, payload);
      })
      .addCase(skip.rejected, (state, { meta: { arg: payload } }) => {
        slice.caseReducers.previous(state);
      })
      // Single classification only
      .addCase(setUniqueAnnotationValue.pending, (state, { meta: { arg: payload } }) => {
        const { output, value } = payload;
        const task = state.entities[payload.task.id];

        const labelIds = new Set(output.labels.map((l) => l.id));
        const labelsByName = output.labels.reduce((acc, l) => ({ ...acc, [l.name]: l }), {});

        const newLabel = labelsByName[value.label];
        const existingAnnotation = task.annotations?.find((ann) => labelIds.has(ann.label_id)) || { external_id: value.maybeId, id: value.maybeId };

        // TODO: flagging this as a cause for concern.
        task.annotations = [{ ...existingAnnotation, label_id: newLabel.id, label_name: newLabel.name } as ClassificationAnnotation as any];
      })
      .addCase(upsertAnnotationStrength.pending, (state, { meta: { arg: payload } }) => {
        const { task: oldTask, output, labelName, strength } = payload;

        const label = output.labels.find((l) => l.name === labelName);
        let annotation = oldTask.annotations?.find((ann) => ann.label_id === label.id);
        const otherAnnotations = oldTask.annotations?.filter((ann) => ann.label_id !== label.id) || [];

        if (annotation) {
          annotation = { ...annotation, strength };
        } else {
          // TODO: not exactly correct type. Works because strict is off.
          annotation = { label_id: label.id, label_name: label.name, strength: strength } as Partial<Annotation> as Annotation;
        }

        const task = state.entities[payload.task.id];
        task.annotations = [...otherAnnotations, annotation];
      })
      .addCase(addAnnotationValue.pending, (state, { meta: { arg: payload } }) => {
        const task = state.entities[payload.task.id];
        const value = task.annotations ? [...task.annotations, payload.annotationValue] : [payload.annotationValue];
        value.sort((a, b) => a.start - b.start);
        task.annotations = value;
      })
      .addCase(removeAnnotationValue.pending, (state, { meta: { arg: payload } }) => {
        const task = state.entities[payload.task.id];
        const value = task.annotations.filter((ann) => ann.id !== payload.annotationId);
        task.annotations = value;
      })
      .addCase(setComment.pending, (state, { meta: { arg: payload } }) => {
        const task = state.entities[payload.task.id];
        task.comment = payload.comment;
      })
      .addCase(toggleComplete.pending, (state, { meta: { arg: payload } }) => {
        const task = state.entities[payload.task.id];
        task.complete = !task.complete;
      })
      .addCase(toggleComplete.fulfilled, (state, { meta: { arg: payload } }) => {
        // slice.caseReducers.next(state, {});
      })
      .addCase(toggleComplete.rejected, (state, { meta: { arg: payload } }) => {
        // slice.caseReducers.previous(state, {});
      })
      .addCase(submit.pending, (state, action) => {
        console.log("SUBMIT", action);
        slice.caseReducers.next(state, action);
        const task = state.entities[action.meta.arg.task.id];
        task.complete = true;
      })
      .addCase(submit.fulfilled, (state, { meta: { arg: payload } }) => {
        // slice.caseReducers.next(state, {});
      })
      .addCase(submit.rejected, (state, { meta: { arg: payload } }) => {
        slice.caseReducers.previous(state);
      })

      // ALL *TASK* UPDATES (named /tasks/task/...)

      // To stop race conditions, where one update's response clobbers another one,
      // we keep track of all the pending requests for this task, and only
      // upsert the response on the final one.
      .addMatcher(isTaskAction("pending"), (state, action) => {
        const task = state.entities[action.meta.arg.task.id];
        task.pendingActions = task?.pendingActions || [];
        task.pendingActions.push(action.meta.requestId);
      })
      .addMatcher(isTaskAction("fulfilled"), (state, action) => {
        const task = state.entities[action.meta.arg.task.id];
        const i = task.pendingActions?.indexOf(action.meta.requestId) || -1;
        if (i > -1) {
          task.pendingActions.splice(i, 1);
        }
        if (isEmpty(task.pendingActions)) {
          slice.caseReducers.upsertOne(state, action.payload);
        }
      })
      .addMatcher(isTaskAction("rejected"), (state, { meta: { arg: payload } }) => {
        try {
          state.entities[payload.task.id] = payload.task;
        } catch (error) {
          console.log({ error });
        }
      })
      // GENERICS
      .addMatcher(isTaskAction("pending"), (state, action) => {
        state.loading = true;
      })
      .addMatcher(isTaskAction("fulfilled"), (state, action) => {
        state.loading = false;
      })
      .addMatcher(isTaskAction("rejected"), (state, action) => {
        state.loading = false;
        console.log({ action });
        if (action.meta.rejectedWithValue) {
          notification.warn({
            placement: "bottomRight",
            message: action.payload?.detail?.msg,
            description: action.payload?.detail?.description,
          });
        } else {
          notification.warn({
            placement: "bottomRight",
            message: "Something went wrong",
            description: (
              <>
                The request was rejected for some reason.{" "}
                <button
                  className="btn btn-link text-blue-600 hover:text-blue-700"
                  onClick={() => {
                    Cohere.widget("show");
                    Cohere.widget("expand");
                  }}
                >
                  Click here to get help.
                </button>
              </>
            ),
          });
          Sentry.captureException(action.error.message);
        }
      });
  },
});

const { actions, reducer } = slice;

// Make the selectors operate on the global state
const globalSelectors = Object.fromEntries(
  Object.entries(selectors).map(([name, sliceSelector]) => {
    return [name, (state) => sliceSelector(state[SLICE_NAME])];
  })
);

const getInputByName = (name) => (state) => state["tasks"].inputs.find((input) => input.name === name);
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  actions: {
    ...actions,
    assignUser,
    flag,
    fetchById,
    setComment,
    setUniqueAnnotationValue,
    upsertAnnotationStrength,
    addAnnotationValue,
    removeAnnotationValue,
    toggleComplete,
    skip,
    submit,
  },
  reducer,
  selectors: globalSelectors,
  selectorCreators: { getInputByName },
};
